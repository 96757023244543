// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_k5";
export var caseStudyBackground__lineColor = "bz_kX";
export var caseStudyHead = "bz_k7";
export var caseStudyHead__imageWrapper = "bz_kV";
export var caseStudyProjectsSection = "bz_k6";
export var caseStudyQuote__bgLight = "bz_k3";
export var caseStudyQuote__bgRing = "bz_k2";
export var caseStudyVideoReview = "bz_lV";
export var caseStudyVideoReview__bgRing = "bz_lW";
export var caseStudyVideo__ring = "bz_k8";
export var caseStudy__bgDark = "bz_kT";
export var caseStudy__bgLight = "bz_kS";
export var caseStudy__bgLightReverse = "bz_lT";